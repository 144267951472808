<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <b-row v-if="purchaseQuoteData == undefined || formShow == true">
      <b-col cols="12">
        <center class="mt-5">Loading...</center>
      </b-col>
    </b-row>

    <b-row v-else class="invoice-preview">
      <b-col cols="12" xl="10" md="9">
        <b-alert variant="danger" class="mb-1" :show="purchaseQuoteData.status == 'D'">
          <div class="alert-body">
            <span v-if="purchaseQuoteData.notes != null"><strong>Reason for rejection : </strong> {{ purchaseQuoteData.notes }}</span>
            <span v-else><strong>Reason for rejection : </strong> No note has been written!</span>
          </div>
        </b-alert>

        <b-card no-body class="invoice-preview-card table-responsive">
          <span style="padding:10px;"><strong>Lease Number :</strong> #{{ Number($route.params.id) }} <strong> Quote Date :</strong> {{ purchaseQuoteData.quoteDate }}</span>

          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">Lessee Identification (The "Lessee")</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="textvalue" v-if="purchaseQuoteData.sellerIdentification.sellerType == 1 || purchaseQuoteData.sellerIdentification.sellerType == null">
                <td colspan="2">
                  <span style="font-size:180%;" v-if="purchaseQuoteData.sellerIdentification.sellerType == 1"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Individual
                </td>
                <td colspan="2">
                  <span style="font-size:180%;" v-if="purchaseQuoteData.sellerIdentification.sellerType == 2"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Business
                </td>
                <td colspan="4" :class="purchaseQuoteData.sellerIdentification.firstName == null ? 'bg-light-danger' : ''">
                  {{ purchaseQuoteData.sellerIdentification.firstName }}
                </td>
                <td colspan="4">{{ purchaseQuoteData.sellerIdentification.lastName }}</td>
              </tr>
              <tr class="title" v-if="purchaseQuoteData.sellerIdentification.sellerType == 1 || purchaseQuoteData.sellerIdentification.sellerType == null">
                <th colspan="4" class="noborderright">Lessee Type</th>
                <th colspan="4" class="noborderright">First Name / Business Name</th>
                <th colspan="4">Last Name / Operating Name</th>
              </tr>

              <tr class="textvalue" v-if="purchaseQuoteData.sellerIdentification.sellerType == 2">
                <td colspan="2">
                  <span style="font-size:180%;" v-if="purchaseQuoteData.sellerIdentification.sellerType == 1"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Individual
                </td>
                <td colspan="2">
                  <span style="font-size:180%;" v-if="purchaseQuoteData.sellerIdentification.sellerType == 2"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Business
                </td>
                <td colspan="2" :class="purchaseQuoteData.sellerIdentification.firstName == null ? 'bg-light-danger' : ''">
                  {{ purchaseQuoteData.sellerIdentification.firstName }}
                </td>
                <td colspan="2">{{ purchaseQuoteData.sellerIdentification.lastName }}</td>
                <td colspan="2" :class="purchaseQuoteData.sellerIdentification.contactPerson == null ? 'bg-light-danger' : ''">
                  {{ purchaseQuoteData.sellerIdentification.contactPerson }}
                </td>
                <td colspan="2">{{ purchaseQuoteData.sellerIdentification.contactInfo }}</td>
              </tr>
              <tr class="title" v-if="purchaseQuoteData.sellerIdentification.sellerType == 2">
                <th colspan="4" class="noborderright">Lessee Type</th>
                <th colspan="2" class="noborderright">First Name / Business Name</th>
                <th colspan="2" class="noborderright">Last Name / Operating Name</th>
                <th colspan="2" class="noborderright">Contact Person</th>
                <th colspan="2">Contact Info</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">{{ purchaseQuoteData.sellerIdentification.driverLicenseNumber }}&nbsp;</td>
                <td colspan="2">{{ purchaseQuoteData.sellerIdentification.gst }}</td>
                <td colspan="4" :class="purchaseQuoteData.sellerIdentification.phoneNumber == null ? 'bg-light-danger' : ''">
                  {{ purchaseQuoteData.sellerIdentification.phoneNumber }}
                </td>
                <td colspan="4" :class="purchaseQuoteData.sellerIdentification.email == null ? 'bg-light-danger' : ''">
                  {{ purchaseQuoteData.sellerIdentification.email }}
                </td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Driver's License Number</th>
                <th colspan="2" class="noborderright">G.S.T Registration</th>
                <th colspan="4" class="noborderright">Phone Number</th>
                <th colspan="4">Email</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">{{ purchaseQuoteData.sellerIdentification.otherIdNumber }}&nbsp;</td>
                <td colspan="2">{{ purchaseQuoteData.sellerIdentification.pst }}</td>
                <td colspan="2">{{ purchaseQuoteData.sellerIdentification.unitNumber }}</td>
                <td colspan="6" :class="purchaseQuoteData.sellerIdentification.streetAddress == null ? 'bg-light-danger' : ''">
                  {{ purchaseQuoteData.sellerIdentification.streetAddress }}
                </td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Other ID Number</th>
                <th colspan="2" class="noborderright">P.S.T Registration</th>
                <th colspan="2" class="noborderright">Unit Number</th>
                <th colspan="6">Street Address</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">{{ purchaseQuoteData.sellerIdentification.dateOfBirth }}&nbsp;</td>
                <td colspan="2">{{ purchaseQuoteData.sellerIdentification.dealer }}</td>
                <td colspan="2" :class="purchaseQuoteData.sellerIdentification.city == null ? 'bg-light-danger' : ''">{{ purchaseQuoteData.sellerIdentification.city }}</td>
                <td colspan="2" :class="purchaseQuoteData.sellerIdentification.provence == null ? 'bg-light-danger' : ''">{{ purchaseQuoteData.sellerIdentification.provence }}</td>
                <td colspan="2" :class="purchaseQuoteData.sellerIdentification.postalCode == null ? 'bg-light-danger' : ''">
                  {{ purchaseQuoteData.sellerIdentification.postalCode }}
                </td>
                <td colspan="2" :class="purchaseQuoteData.sellerIdentification.country == null ? 'bg-light-danger' : ''">{{ purchaseQuoteData.sellerIdentification.country }}</td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Date of Birth</th>
                <th colspan="2" class="noborderright">Dealer Registration</th>
                <th colspan="2" class="noborderright">City</th>
                <th colspan="2" class="noborderright">Province</th>
                <th colspan="2" class="noborderright">Postal Code</th>
                <th colspan="2">Country</th>
              </tr>
            </tbody>
          </table>
        </b-card>

        <b-card no-body class="invoice-preview-card table-responsive">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">Co-Lessee Information</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="textvalue">
                <td colspan="2">
                  <span style="font-size:180%;"> &#9744;</span>
                  Individual
                </td>
                <td colspan="2">
                  <span style="font-size:180%;"> &#9744;</span>
                  Business
                </td>
                <td colspan="4"></td>
                <td colspan="4"></td>
              </tr>
              <tr class="title">
                <th colspan="4" class="noborderright">Lessee Type</th>
                <th colspan="4" class="noborderright">First Name / Business Name</th>
                <th colspan="4">Last Name / Operating Name</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">&nbsp;</td>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Driver's License Number</th>
                <th colspan="2" class="noborderright">G.S.T Registration</th>
                <th colspan="4" class="noborderright">Phone Number</th>
                <th colspan="4">Email</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">&nbsp;</td>
                <td colspan="2"></td>
                <td colspan="2"></td>
                <td colspan="6"></td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Other ID Number</th>
                <th colspan="2" class="noborderright">P.S.T Registration</th>
                <th colspan="2" class="noborderright">Unit Number</th>
                <th colspan="6">Street Address</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">&nbsp;</td>
                <td colspan="2"></td>
                <td colspan="2"></td>
                <td colspan="2"></td>
                <td colspan="2"></td>
                <td colspan="2"></td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Date of Birth</th>
                <th colspan="2" class="noborderright">Dealer Registration</th>
                <th colspan="2" class="noborderright">City</th>
                <th colspan="2" class="noborderright">Province</th>
                <th colspan="2" class="noborderright">Postal Code</th>
                <th colspan="2">Country</th>
              </tr>
            </tbody>
          </table>
        </b-card>

        <b-card v-for="item in purchaseQuoteData.coOwner" :key="item.customerId" no-body class="invoice-preview-card table-responsive">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">Co-Lessee Information</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="textvalue" v-if="item.sellerType == 1 || item.sellerType == null">
                <td colspan="2">
                  <span style="font-size:180%;" v-if="item.sellerType == 1"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Individual
                </td>
                <td colspan="2">
                  <span style="font-size:180%;" v-if="item.sellerType == 2"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Business
                </td>
                <td colspan="4">{{ item.firstName }}</td>
                <td colspan="4">{{ item.lastName }}</td>
              </tr>

              <tr class="textvalue" v-if="item.sellerType == 2">
                <td colspan="2">
                  <span style="font-size:180%;" v-if="item.sellerType == 1"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Individual
                </td>
                <td colspan="2">
                  <span style="font-size:180%;" v-if="item.sellerType == 2"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Business
                </td>
                <td colspan="2">{{ item.firstName }}</td>
                <td colspan="2">{{ item.lastName }}</td>
                <td colspan="2">{{ item.contactPerson }}</td>
                <td colspan="2">{{ item.contactInfo }}</td>
              </tr>

              <tr class="title" v-if="item.sellerType == 1 || item.sellerType == null">
                <th colspan="4" class="noborderright">Lessee Type</th>
                <th colspan="4" class="noborderright">First Name / Business Name</th>
                <th colspan="4">Last Name / Operating Name</th>
              </tr>

              <tr class="title" v-if="item.sellerType == 2">
                <th colspan="4" class="noborderright">Lessee Type</th>
                <th colspan="2" class="noborderright">First Name / Business Name</th>
                <th colspan="2" class="noborderright">Last Name / Operating Name</th>
                <th colspan="2" class="noborderright">Contact Person</th>
                <th colspan="2">Contact Info</th>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Driver's License Number</th>
                <th colspan="2" class="noborderright">G.S.T Registration</th>
                <th colspan="4" class="noborderright">Phone Number</th>
                <th colspan="4">Email</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">{{ item.otherIdNumber }}</td>
                <td colspan="2">{{ item.pst }}</td>
                <td colspan="2">{{ item.unitNumber }}</td>
                <td colspan="6">{{ item.streetAddress }}</td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Other ID Number</th>
                <th colspan="2" class="noborderright">P.S.T Registration</th>
                <th colspan="2" class="noborderright">Unit Number</th>
                <th colspan="6">Street Address</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">{{ item.dateOfBirth }}</td>
                <td colspan="2">{{ item.dealer }}</td>
                <td colspan="2">{{ item.city }}</td>
                <td colspan="2">{{ item.provence }}</td>
                <td colspan="2">{{ item.postalCode }}</td>
                <td colspan="2">{{ item.country }}</td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Date of Birth</th>
                <th colspan="2" class="noborderright">Dealer Registration</th>
                <th colspan="2" class="noborderright">City</th>
                <th colspan="2" class="noborderright">Province</th>
                <th colspan="2" class="noborderright">Postal Code</th>
                <th colspan="2">Country</th>
              </tr>
            </tbody>
          </table>
        </b-card>

        <b-row>
          <b-col cols="12" md="12" xl="12">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Description of Vehicle (The "Vehicle")</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="textvalue">
                    <td :class="purchaseQuoteData.vehicleCondition == null ? 'bg-light-danger' : ''">
                      {{ purchaseQuoteData.vehicleCondition ? purchaseQuoteData.vehicleCondition : '&nbsp;' }}
                    </td>
                    <td :class="purchaseQuoteData.vehicleMake == null ? 'bg-light-danger' : ''">
                      {{ purchaseQuoteData.vehicleMake ? purchaseQuoteData.vehicleMake : '&nbsp;' }}
                    </td>
                    <td>{{ purchaseQuoteData.vehicleModel ? purchaseQuoteData.vehicleModel : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th style="width:33%">Condition</th>
                    <th style="width:34%">Make</th>
                    <th style="width:33%">Model</th>
                  </tr>

                  <tr class="textvalue">
                    <td>{{ purchaseQuoteData.vehicleYear ? purchaseQuoteData.vehicleYear : '&nbsp;' }}</td>
                    <td>{{ purchaseQuoteData.vehicleFuel ? purchaseQuoteData.vehicleFuel : '&nbsp;' }}</td>
                    <td>{{ purchaseQuoteData.vehicleTrim ? purchaseQuoteData.vehicleTrim : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th>Model Year</th>
                    <th>Fuel Type</th>
                    <th>Trim</th>
                  </tr>

                  <tr class="textvalue">
                    <td>{{ purchaseQuoteData.vehicleBodyType ? purchaseQuoteData.vehicleBodyType : '&nbsp;' }}</td>
                    <td :class="purchaseQuoteData.vehicleMileage == null ? 'bg-light-danger' : ''">
                      {{ purchaseQuoteData.vehicleMileage ? purchaseQuoteData.vehicleMileage : '&nbsp;' }}
                    </td>
                    <td :class="purchaseQuoteData.vehicleMileageUnit == null ? 'bg-light-danger' : ''">
                      {{ purchaseQuoteData.vehicleMileageUnit ? purchaseQuoteData.vehicleMileageUnit : '&nbsp;' }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th>Body Type</th>
                    <th>Mileage</th>
                    <th>Mileage Unit</th>
                  </tr>

                  <tr class="textvalue">
                    <td :class="purchaseQuoteData.vehicleExteriorColor == null ? 'bg-light-danger' : ''">
                      {{ purchaseQuoteData.vehicleExteriorColor ? purchaseQuoteData.vehicleExteriorColor : '&nbsp;' }}
                    </td>
                    <td>{{ purchaseQuoteData.vehicleTransmission ? purchaseQuoteData.vehicleTransmission : '&nbsp;' }}</td>
                    <td>{{ purchaseQuoteData.vehicleDrivetrain ? purchaseQuoteData.vehicleDrivetrain : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th>Exterior Colour</th>
                    <th>Transmission</th>
                    <th>Drivetrain</th>
                  </tr>

                  <tr class="textvalue">
                    <td>{{ purchaseQuoteData.vehicleInteriorColor ? purchaseQuoteData.vehicleInteriorColor : '&nbsp;' }}</td>
                    <td>{{ purchaseQuoteData.vehicleStockNumber ? purchaseQuoteData.vehicleStockNumber : '&nbsp;' }}&nbsp;</td>
                    <td :class="purchaseQuoteData.vehicleVIN == null ? 'bg-light-danger' : ''">{{ purchaseQuoteData.vehicleVIN ? purchaseQuoteData.vehicleVIN : '&nbsp;' }} &nbsp;</td>
                  </tr>

                  <tr class="title">
                    <th>Interior Colour</th>
                    <th>Stock Number</th>
                    <th>Vehicle Identification Number</th>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="12" xl="12">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">LEASE DISCLOSURE</th>
                  </tr>
                </thead>

                <tbody class="text-start">
                  <tr class="textproduct">
                    <th colspan="12" style="width:100%">1)Cost of Lease Disclosure/Vehicle Value</th>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="11">a)Vehicle Price (includes amounts for additional equipment, accessories, freight, pre-delivery inspection, but does not include GST or PST)</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.leasedPrice ? purchaseQuoteData.leasedPrice : 0, 2) }}</td>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="11">b)Dealer Added Options</td>
                    <td colspan="1" class="text-center">0.00</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">c)Cash Down Payment</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.cashDown ? purchaseQuoteData.cashDown : 0, 2) }}</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">d)Trade-in Allowance</td>
                    <td colspan="1" class="text-center">0.00</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">e)Balance Owing on Trade-in</td>
                    <td colspan="1" class="text-center">0.00</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">f)Net Amount Used In Calculating Lease Payment</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.netCap ? purchaseQuoteData.netCap : 0, 2) }}</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">g)Estimated Residue Value</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.residual ? purchaseQuoteData.residual : 0, 2) }}</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">h)Amount to Be Amortized</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.amortized ? purchaseQuoteData.amortized : 0, 2) }}</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">i)Residual is based on estimated Odometer KM at Lease end</td>
                    <td colspan="1" class="text-center"></td>
                  </tr>
                </tbody>

                <tbody class="text-start">
                  <tr class="textproduct">
                    <th colspan="12" style="width:100%">2)Interest Rate and Charges</th>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="11">a)Interest Rate</td>
                    <td colspan="1" class="text-center">{{ purchaseQuoteData.rate ? purchaseQuoteData.rate : 0 }}</td>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="11">b)Total Interest Charges</td>
                    <td colspan="1" class="text-center">0.00</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">c)Lease Term in Months</td>
                    <td colspan="1" class="text-center">{{ purchaseQuoteData.term ? purchaseQuoteData.term : 1 }}</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">d)Lease Payment</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.basePayment ? purchaseQuoteData.basePayment : 0, 2) }}</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">e)Applicable Taxes (GST)</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.gstAmountVehicle ? purchaseQuoteData.gstAmountVehicle : 0, 2) }}</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">f)Applicable Taxes (PST)</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.pstAmountVehicle ? purchaseQuoteData.pstAmountVehicle : 0, 2) }}</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">g)Monthly Payment</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.netPayment ? purchaseQuoteData.netPayment : 0, 2) }}</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">h)Total Payments</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.dueDelivery ? purchaseQuoteData.dueDelivery : 0, 2) }}</td>
                  </tr>
                </tbody>

                <tbody class="text-start">
                  <tr class="textproduct">
                    <th colspan="12" style="width:100%">3)Lease Term and Payment Due Dates</th>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="11">a)First Monthly Payment Due Date:</td>
                    <td colspan="1" class="text-center">27/10/2022</td>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="11">b)The remaining {{ parseInt(purchaseQuoteData.term) - 1 }} Monthly Payments of</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.netPayment ? purchaseQuoteData.netPayment : 0, 2) }}</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">c)will be due on the</td>
                    <td colspan="1" class="text-center">1st of every month thereafter</td>
                  </tr>
                </tbody>

                <tbody class="text-start">
                  <tr class="textproduct">
                    <th colspan="12" class="text-start">4)Amount Due at Lease Inception</th>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="11">a)Cash Down Payment</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.cashDown ? purchaseQuoteData.cashDown : 0, 2) }}</td>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="11">b)Trade as Down Payment</td>
                    <td colspan="1" class="text-center">0.00</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">c)Refundable Security Deposit</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.securityDeposit ? purchaseQuoteData.securityDeposit : 0, 2) }}</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">d)1st Monthly Payment</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.basePayment ? purchaseQuoteData.basePayment : 0, 2) }}</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">e)Single Payment</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.netPayment ? purchaseQuoteData.netPayment : 0, 2) }}</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">f)Pro-Rata</td>
                    <td colspan="1" class="text-center">0.00</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">g)Lease Registration Fee</td>
                    <td colspan="1" class="text-center">0.00</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">h)Administrative Fee</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.monthlyAdmin ? purchaseQuoteData.monthlyAdmin : 0, 2) }}</td>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="11">i)Licensing</td>
                    <td colspan="1" class="text-center">0.00</td>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="11">j)Other Charges</td>
                    <td colspan="1" class="text-center">0.00</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">k)SUB-TOTAL</td>
                    <td colspan="1" class="text-center">1288000</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">l)Applicable Taxes (GST)</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.gstAmountVehicle ? purchaseQuoteData.gstAmountVehicle : 0, 2) }}</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">m)Applicable Taxes (PST)</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.pstAmountVehicle ? purchaseQuoteData.pstAmountVehicle : 0, 2) }}</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">n)Trade Cashback or Shortfall</td>
                    <td colspan="1" class="text-center">0.00</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">o)Tax on Shortfall</td>
                    <td colspan="1" class="text-center">0.00</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">p)Less: Deposit</td>
                    <td colspan="1" class="text-center">0.00</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">q)TOTAL AMOUNT DUE ON SIGNING</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.beforeTax ? purchaseQuoteData.beforeTax : 0, 2) }}</td>
                  </tr>
                </tbody>

                <tbody class="text-start">
                  <tr class="textproduct">
                    <th colspan="12" style="width:100%">5)Total Cost of Lease upon Completion, if You Do Not Elect to Purchase the Vehicle</th>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="12"><strong>Excluding security deposit, any charges for excess wear and tear, excess kilometers allowed, and late payments.</strong></td>
                  </tr>
                </tbody>

                <tbody class="text-start">
                  <tr class="textproduct">
                    <th colspan="12" style="width:100%">6)Lease Cost Disclosure</th>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="11">a)Value of Leased Goods</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.leasedPrice ? purchaseQuoteData.leasedPrice : 0, 2) }}</td>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="11">b)Value Given by You</td>
                    <td colspan="1" class="text-center">0.00</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">c)Value Received by You</td>
                    <td colspan="1" class="text-center">{{ formatPrice(purchaseQuoteData.leasedPrice ? purchaseQuoteData.leasedPrice : 0, 2) }}</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">d)Capitalized Amount</td>
                    <td colspan="1" class="text-center">0.00</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">e)Implicit Finance Charge</td>
                    <td colspan="1" class="text-center">0.00</td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="11">f)Annual Percentage Rate</td>
                    <td colspan="1" class="text-center">{{ purchaseQuoteData.rate ? purchaseQuoteData.rate : 0 }}</td>
                  </tr>
                </tbody>

                <tbody class="text-start">
                  <tr class="textproduct">
                    <th colspan="12" style="width:100%">7)Residual Obligation Lease</th>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="12">
                      You Guarantee the Contract Value of the vehicle throughout the Term of the Lease and its Residual Value at Lease End (Section 1(f)). You understand that the Contract Value (Guaranteed Value) of the vehicle may not be equal to its Wholesale Value*. You also understand that if
                      the Residual Value had been set lower, it would increase your Lease Payments and lower the amount you would have to guarantee. The Vehicle 's Residual Value 1(1) is estimated based on the Expected Kilometres it will have at the end of the Lease 1(h). The Residual Value 1(1) of
                      the vehicle will decrease by 1% for every 1,000 km's driven over the Expected Kilometres at Lease End 1(h). A. If you purchase the vehicle, you agree to pay the Contract Value of the vehicle; plus a Purchase Option Fee of $250; plus safety inspection, emissions testing and
                      other government charges and taxes imposed in connection with the purchase of the Vehicle. B. If the vehicle is returned voluntarily by you, or involuntarily as a result of repossession, you will be required to pay us, or receive from us, the difference between the Contract
                      Value and Wholesale Value of the vehicle, plus applicable taxes.
                    </td>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="12">DEFINITIONS</td>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="12">The Contract Value of the vehicle will be calculated as follows: i) The Residual Value 1(f); plus ii) The Base Lease Payment 2(d) times the remaining number of payments; plus iii) Any past due Monthly Payments; plus iv) Any other amount due under the Lease</td>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="12">
                      In order to determine the Wholesale Value of the vehicle , the Lessor shall, and the Lessee(s) may (on behalf of the Lessor), solicit bids from prospective purchasers in the Wholesale vehicle market for the surrendered vehicle on an as-is basis, without recourse or warranty. If
                      the vehicle is not sold within 20 days of the Lease Expiry Date, the Lessor shall have the right to sell the vehicle to any dealer, broker, or any other party, including to or through companies affiliated with the Lessor.
                    </td>
                  </tr>
                </tbody>

                <tbody class="text-start">
                  <tr class="textproduct">
                    <th colspan="12" style="width:100%">8)Delivery Receipt</th>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="12" style="width:100%">
                      You acknowledge that you have received and examined the Vehicle described on the 1st page of the Vehicle Lease Agreement. The Vehicle is equipped as described below and is in good operating order and condition and you accept it for all purposes of the Lease:
                    </td>
                  </tr>
                  <tr class="textproduct">
                    <td colspan="12" style="width:100%">The Vehicle is equipped with the following:</td>
                  </tr>

                  <tr class="textproduct">
                    <td colspan="3" style="width:25%">Date</td>
                    <td colspan="3" style="width:25%" class="text-center"></td>
                    <td colspan="3" style="width:25%">Odometer Confirmed</td>
                    <td colspan="3" style="width:25%" class="text-center"></td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>

        <!-- <b-row>
          <b-col cols="12">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Vehicle Appraisal</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="textvalue">
                    <td colspan="1" style="width:10%"
                      :class="purchaseQuoteData.vehicleTitleType == null ? 'bg-light-danger' : ''">
                      <span v-if="purchaseQuoteData.vehicleTitleType == 1" style="font-size:180%;">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="4" style="width:20%">Clean Owned</td>
                    <td colspan="7" rowspan="5" class="text-left align-top" style="width:70%">
                      {{ purchaseQuoteData.appraisalNotes }}
                    </td>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="1" :class="purchaseQuoteData.vehicleTitleType == null ? 'bg-light-danger' : ''">
                      <span v-if="purchaseQuoteData.vehicleTitleType == 2" style="font-size:180%;">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="4">Owned with Lien Payout</td>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="1" :class="purchaseQuoteData.vehicleTitleType == null ? 'bg-light-danger' : ''">
                      <span v-if="purchaseQuoteData.vehicleTitleType == 3" style="font-size:180%;">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="4">Leased</td>
                  </tr>

                  <tr class="title">
                    <th colspan="5">Vehicle Title Type</th>
                  </tr>

                  <tr class="textvalue"
                    :class="purchaseQuoteData.appraisedVehiclePrice == null ? 'bg-light-danger' : ''">
                    <td colspan="5">{{ purchaseQuoteData.appraisedVehiclePrice ?
                    formatPrice(purchaseQuoteData.appraisedVehiclePrice) : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="5">Appraised Vehicle Price</th>
                    <th colspan="7">Appraisal Notes</th>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row> -->

        <!-- <b-row>
          <b-col cols="12" md="4" xl="4">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Lien Payout</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="title">
                    <td colspan="1" style="width:10%">
                      <span v-if="purchaseQuoteData.payoutType == 1" style="font-size:180%;">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="11" style="width:90%">Finance Payout</td>
                  </tr>

                  <tr class="title">
                    <td colspan="1">
                      <span v-if="purchaseQuoteData.payoutType == 2" style="font-size:180%;">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="11">Lease Payout</td>
                  </tr>

                  <tr class="title">
                    <th colspan="12">Payout Type</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="12">{{ purchaseQuoteData.beforeTax ? formatPrice(purchaseQuoteData.beforeTax) :
                    '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="12">Net Payout Before Tax</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="6">{{ purchaseQuoteData.gstRate ? formatPrice(purchaseQuoteData.gstRate) + '%' :
                    '&nbsp;' }}</td>
                    <td colspan="6">
                      {{ purchaseQuoteData.beforeTax ? formatPrice((purchaseQuoteData.beforeTax *
                      (purchaseQuoteData.gstRate ? purchaseQuoteData.gstRate : 0)) / 100) : '&nbsp;' }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="6" style="width:40%">G.S.T Rate</th>
                    <th colspan="6" style="width:60%">G.S.T Amount</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="12">
                      {{ purchaseQuoteData.beforeTax ? formatPrice((purchaseQuoteData.beforeTax *
                      (purchaseQuoteData.gstRate ? purchaseQuoteData.gstRate : 0)) / 100 + purchaseQuoteData.beforeTax)
                      : '&nbsp;' }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="12">Total Payout Amount</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="12">{{ purchaseQuoteData.unitlDate ? purchaseQuoteData.unitlDate : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="12">Good Until Date</th>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>

          <b-col cols="12" md="8" xl="8">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Lien Holder Information</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="title">
                    <td colspan="1" style="width:3%">
                      <span style="font-size:180%;"
                        v-if="(purchaseQuoteData.get_lien_customer ? purchaseQuoteData.get_lien_customer.sellerType : 0) == 1">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="3" style="width:21%">Individual</td>

                    <td colspan="4" rowspan="2">{{ purchaseQuoteData.get_lien_customer ?
                    purchaseQuoteData.get_lien_customer.firstName : '&nbsp;' }}</td>
                    <td colspan="4" rowspan="2">{{ purchaseQuoteData.get_lien_customer ?
                    purchaseQuoteData.get_lien_customer.lastName : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <td colspan="1">
                      <span style="font-size:180%;"
                        v-if="(purchaseQuoteData.get_lien_customer ? purchaseQuoteData.get_lien_customer.sellerType : 0) == 2">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="3">Business</td>
                  </tr>
                  <tr class="title">
                    <th colspan="4" class="noborderright" style="width:24%">Seller Type</th>
                    <th colspan="4" class="noborderright" style="width:38%">First Name / Business Name</th>
                    <th colspan="4" style="width:38%">Last Name / Operating Name</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="4">{{ purchaseQuoteData.get_lien_customer ?
                    purchaseQuoteData.get_lien_customer.driverLicenseNumber : '&nbsp;' }}</td>
                    <td colspan="4">{{ purchaseQuoteData.get_lien_customer ?
                    purchaseQuoteData.get_lien_customer.phoneNumber : '&nbsp;' }}</td>
                    <td colspan="4">{{ purchaseQuoteData.get_lien_customer ? purchaseQuoteData.get_lien_customer.email :
                    '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="4" class="noborderright">Driver's License Number</th>
                    <th colspan="4" class="noborderright">Phone Number</th>
                    <th colspan="4">Email</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="4">{{ purchaseQuoteData.get_lien_customer ? purchaseQuoteData.get_lien_customer.gst :
                    '&nbsp;' }}</td>
                    <td colspan="4">{{ purchaseQuoteData.get_lien_customer ?
                    purchaseQuoteData.get_lien_customer.unitNumber : '&nbsp;' }}</td>
                    <td colspan="4" rowspan="3" class="text-left align-top">
                      {{ purchaseQuoteData.get_lien_customer ? purchaseQuoteData.get_lien_customer.streetAddress :
                      '&nbsp;' }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="4" class="noborderright">G.S.T Registration</th>
                    <th colspan="4">Unit Number</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="4">{{ purchaseQuoteData.get_lien_customer ? purchaseQuoteData.get_lien_customer.pst :
                    '&nbsp;' }}</td>
                    <td colspan="4">{{ purchaseQuoteData.get_lien_customer ? purchaseQuoteData.get_lien_customer.city :
                    '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="4" class="noborderright">P.S.T Registration</th>
                    <th colspan="4" class="noborderright">City</th>
                    <th colspan="4">Street Address</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="3">{{ purchaseQuoteData.get_lien_customer ? purchaseQuoteData.get_lien_customer.dealer
                    : '&nbsp;' }}</td>
                    <td colspan="3">{{ purchaseQuoteData.get_lien_customer ?
                    purchaseQuoteData.get_lien_customer.provence : '&nbsp;' }}</td>
                    <td colspan="3">{{ purchaseQuoteData.get_lien_customer ?
                    purchaseQuoteData.get_lien_customer.postalCode : '&nbsp;' }}</td>
                    <td colspan="3">{{ purchaseQuoteData.get_lien_customer ? purchaseQuoteData.get_lien_customer.country
                    : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="3" class="noborderright">Dealer Registration</th>
                    <th colspan="3" class="noborderright">Province</th>
                    <th colspan="3" class="noborderright">Postal Code</th>
                    <th colspan="3">Country</th>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row> -->

        <b-row>
          <b-col cols="12">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Price Calculation</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="title">
                    <td colspan="1" style="width:1%">
                      <span style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="3" style="width:19%">Buy-in</td>
                    <td colspan="4" style="width:40%; border-bottom:hidden"></td>
                    <th colspan="2" style="width:20%" class="text-right">Vehicle Leased Price</th>
                    <td colspan="2" style="width:10%">{{ formatPrice(purchaseQuoteData.leasedPrice, 2) }}</td>
                  </tr>

                  <tr class="title">
                    <td colspan="1">
                      <span style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="3">Trade-in</td>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <th colspan="2" class="text-right">Cash Down</th>
                    <td colspan="2">{{ formatPrice(purchaseQuoteData.cashDown, 2) }}</td>
                  </tr>

                  <tr class="title">
                    <td colspan="1">
                      <span style="font-size:180%;">&#x2611;</span>
                    </td>
                    <td colspan="3">Lease</td>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <th colspan="2" class="text-right">Trade as Cash Down</th>
                    <td colspan="2">{{ formatPrice(0, 2) }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="3">Contract Type</th>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <th colspan="2" class="text-right">Net Cap</th>
                    <td colspan="2">{{ formatPrice(purchaseQuoteData.netCap, 2) }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="3" style=" border-right:hidden; border-left:hidden"></th>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <th colspan="3" class="text-right">Residual</th>
                    <td colspan="2">{{ formatPrice(purchaseQuoteData.residual, 2) }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="3" style=" border:hidden"></th>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <th colspan="3" class="text-right">Amount Amortized</th>
                    <td colspan="2">{{ formatPrice(purchaseQuoteData.amortized, 2) }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="3" style=" border:hidden"></th>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <th colspan="3" class="text-right">Security Deposit</th>
                    <td colspan="2">{{ formatPrice(purchaseQuoteData.securityDeposit, 2) }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="3" style=" border:hidden"></th>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <th colspan="3" class="text-right">GST Amount on Vehicle</th>
                    <td colspan="1">{{ formatPrice(purchaseQuoteData.gstRateVehicle, 2) }}%</td>
                    <td colspan="1">{{ formatPrice(purchaseQuoteData.gstAmountVehicle, 2) }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="3" style=" border:hidden"></th>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <th colspan="3" class="text-right">PST Amount on Vehicle</th>
                    <td colspan="1">{{ formatPrice(purchaseQuoteData.pstRateVehicle, 2) }}%</td>
                    <td colspan="1">{{ formatPrice(purchaseQuoteData.pstAmountVehicle, 2) }}</td>
                  </tr>
                  <br /><br />

                  <br /><br />
                  <tr class="title">
                    <th colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">Due On Delivery</th>
                    <td colspan="2">{{ formatPrice(purchaseQuoteData.dueDelivery, 2) }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">Term</th>
                    <td colspan="2">{{ purchaseQuoteData.term }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;"><i>Rate</i></th>
                    <td colspan="2">{{ formatPrice(purchaseQuoteData.rate, 2) }}%</td>
                  </tr>

                  <tr class="title">
                    <th colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">Base Payment</th>
                    <td colspan="2">{{ formatPrice(purchaseQuoteData.basePayment, 2) }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">
                      Monthly Admin
                    </th>
                    <td colspan="2">{{ formatPrice(purchaseQuoteData.monthlyAdmin, 2) }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">
                      Net Payment Before Tax
                    </th>
                    <td colspan="2">{{ formatPrice(purchaseQuoteData.netPayment, 2) }}</td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Comments, Promises, Restrictions, Limitations and Conditions</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="title">
                    <td colspan="12" style="height:250px; font-size:14px; text-align:left; vertical-align:top">
                      {{ purchaseQuoteData.comments }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" xl="2" md="3" class="invoice-actions">
        <b-card v-if="purchaseQuoteData.tradeStatus != 1 && purchaseQuoteData.tradeStatus != 2">
          <!-- Button: Send Invoice -->

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_create')" v-if="purchaseQuoteData.status == 'A' && userRole != 'supermanager'" @click="statusChange('B')" variant="primary" class="mb-75" block>
            Send to Manager
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_approve')" v-if="purchaseQuoteData.status == 'B' || purchaseQuoteData.status == 'A'" @click="statusChange('C')" variant="success" class="mb-75" block>
            Approve the Quote
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_refuse')" v-if="purchaseQuoteData.status == 'B' || purchaseQuoteData.status == 'A'" @click="statusChange('D')" variant="danger" class="mb-75" block>
            Refuse the Quote
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_send_client')" v-if="purchaseQuoteData.status == 'C'" @click="statusChange('E')" variant="info" class="mb-75" block>
            Send to Client
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_bypass_client')" v-if="(purchaseQuoteData.status == 'E' || purchaseQuoteData.status == 'C') && purchaseQuoteData.statusCustomer == 'blank'" @click="statusChange('F')" variant="success" class="mb-75" block>
            Mark as Won
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_revise')" v-if="(purchaseQuoteData.status == 'E' || purchaseQuoteData.status == 'C') && purchaseQuoteData.statusCustomer == 'blank'" @click="statusChange('R')" variant="warning" class="mb-75" block>
            Revise the Quote
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_mark_lost')" v-if="purchaseQuoteData.status == 'E' && purchaseQuoteData.statusCustomer == 'blank'" @click="statusChange('MarkAsLost')" variant="danger" class="mb-75" block>
            Mark as Lost
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_revise')" v-if="purchaseQuoteData.status == 'E' && purchaseQuoteData.statusCustomer == 'notapprove'" @click="statusChange('R')" variant="warning" class="mb-75" block>
            Revise the Quote
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_mark_lost')" v-if="purchaseQuoteData.status == 'E' && purchaseQuoteData.statusCustomer == 'notapprove'" @click="statusChange('MarkAsLost')" variant="danger" class="mb-75" block>
            Mark as Lost
          </b-button>
          <!-- finish -->

          <!-- Client Approved  -->
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_mark_won')" v-if="purchaseQuoteData.status == 'E' && purchaseQuoteData.statusCustomer == 'approve'" @click="statusChange('F')" variant="success" class="mb-75" block>
            Mark as Won
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_revise')" v-if="purchaseQuoteData.status == 'E' && purchaseQuoteData.statusCustomer == 'approve'" @click="statusChange('R')" variant="warning" class="mb-75" block>
            Revise the Quote
          </b-button>
          <!-- finish -->

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_mark_lost')" v-if="purchaseQuoteData.status == 'D'" @click="statusChange('MarkAsLost')" variant="danger" class="mb-75" block>
            Mark as Lost
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_revise')" v-if="purchaseQuoteData.status == 'D'" @click="statusChange('R')" variant="warning" class="mb-75" block>
            Revise the Quote
          </b-button>

          <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_mark_complete')" v-if="
            purchaseQuoteData.status == 'F' &&
              (purchaseQuoteData.get_inventory.status == 'G' ||
                purchaseQuoteData.get_inventory.status == 'PA' ||
                purchaseQuoteData.get_inventory.status == 'PB' ||
                purchaseQuoteData.get_inventory.status == 'PC' ||
                purchaseQuoteData.get_inventory.status == 'PD' ||
                purchaseQuoteData.get_inventory.status == 'PH' ||
                purchaseQuoteData.get_inventory.status == 'PI' ||
                purchaseQuoteData.get_inventory.status == 'PE' ||
                purchaseQuoteData.get_inventory.status == 'PK') &&
              purchaseQuoteData.payment == 'yes'
          " @click="statusChange('MarkComplete')" variant="success" class="mb-75" block>
            Complete
          </b-button> -->

          <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('mark_payment_made')" v-if="
            purchaseQuoteData.payment == 'no' &&
              (purchaseQuoteData.get_inventory.status == 'I' || purchaseQuoteData.get_inventory.status == 'F' || purchaseQuoteData.get_inventory.status == 'PF' || purchaseQuoteData.get_inventory.status == 'PA' || purchaseQuoteData.get_inventory.status == 'PK') &&
              purchaseQuoteData.status != 'P' &&
              purchaseQuoteData.status != 'J'
          " @click="statusChange('PM')" variant="success" class="mb-75" block>
            Payment Made
          </b-button> -->

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_cancel') && this.where == 'lease-preview'" v-if="purchaseQuoteData.status == 'F' || purchaseQuoteData.status == 'H'" @click="statusChange('MarkCancel')" variant="danger" class="mb-75" block>
            Cancel
          </b-button>

          <!-- Button: DOwnload -->
          <b-overlay :show="download" spinner-variant="primary" spinner-type="grow" spinner-small>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" @click="pdfDownload($route.params.id)" variant="secondary" class="mb-75" block>
              Save as Pdf
            </b-button>
          </b-overlay>

          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-show="$Can('purchase_quote_edit')" v-if="purchaseQuoteData.status == 'A'" variant="outline-primary" class="mb-75" block :to="{ name: 'quote-edit', params: { id: $route.params.id } }">
            Edit
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="purchaseQuoteData.archive != 'yes'" @click="goToVehicle(purchaseQuoteData.inventoryId)" variant="info" class="mb-75" block>
            Go to Vehicle
          </b-button>
        </b-card>

        <b-card v-else>
          <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('mark_payment_made')" v-if="
            purchaseQuoteData.payment == 'no' &&
              (purchaseQuoteData.get_inventory.status == 'I' || purchaseQuoteData.get_inventory.status == 'F' || purchaseQuoteData.get_inventory.status == 'PF' || purchaseQuoteData.get_inventory.status == 'PA' || purchaseQuoteData.get_inventory.status == 'PK') &&
              purchaseQuoteData.status != 'P' &&
              purchaseQuoteData.tradeStatus != 2
          " @click="statusChange('PM')" variant="success" class="mb-75" block>
            Payment Made
          </b-button> -->

          <b-overlay :show="download" spinner-variant="primary" spinner-type="grow" spinner-small>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" @click="pdfDownload($route.params.id)" variant="secondary" class="mb-75" block>
              Save as Pdf
            </b-button>
          </b-overlay>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="purchaseQuoteData.archive != 'yes'" @click="goToVehicle(purchaseQuoteData.inventoryId)" variant="info" class="mb-75" block>
            Go to Vehicle
          </b-button>
        </b-card>

        <b-card v-show="where == 'lease-preview'">
          <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-toggle.sidebar-send-purchase @click="addNewFile()" size="sm" block variant="primary" v-if="userRole != 'supermanager'" v-show="purchaseQuoteData.status == 'A' || purchaseQuoteData.status == 'B'"> Add New File </b-button> -->
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-toggle.sidebar-send-purchase @click="addNewFile()" size="sm" block variant="primary"> Add New File </b-button>

          <!-- user suggestion  -->
          <div v-for="(data, index) in files" :key="index" class="d-flex justify-content-start align-items-center" :class="index == 0 ? 'mt-2' : 'mt-1'">
            <b-img src="@/assets/images/file.png" @click="fileEdit(data)" class="mr-50 " style="cursor:pointer" width="25px" />
            <div class="user-page-info pr-1" style="width: 100%">
              <small @click="fileEdit(data)" style="cursor:pointer; font-size:.8em">{{ data.fileName }} </small>
            </div>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" target="_blank" :href="fileURL + data.fileLink" style="cursor:alias; padding: 1% 1%; margin-right:2px" class="btn-icon ml-auto" size="sm">
              <feather-icon icon="DownloadIcon" />
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" @click="deleteFile(data)" v-if="userRole != 'supermanager'" v-show="purchaseQuoteData.status == 'A' || purchaseQuoteData.status == 'B'" class="btn-icon ml-auto" style="padding: 1% 1%;" size="sm">
              <feather-icon icon="XIcon" />
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" @click="deleteFile(data)" v-else class="btn-icon ml-auto" style="padding: 1% 1%;" size="sm">
              <feather-icon icon="XIcon" />
            </b-button>
          </div>

          <div class="d-flex justify-content-center align-items-center mt-1 mb-1">
            No Files
          </div>
        </b-card>

        <app-timeline class="mt-2">
          <app-timeline-item v-for="item in purchaseQuoteData.get_timelines" :key="item.id" :title="item.title" :subtitle="'By ' + item.username" :icon="varicon(item.status)" :time="dateFormat(item.created_at)" :variant="varyant(item.status)" />
        </app-timeline>
      </b-col>
    </b-row>

    <b-modal id="modal-file" cancel-variant="outline-secondary" ok-title="Save File" @ok="handleOk" @show="resetModal" @hidden="resetModal" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New File">
      <b-form ref="fileRules">
        <b-form-group :state="nameState" invalid-feedback="File Name is required">
          <label for="fileName">File Name:</label>
          <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
        </b-form-group>
        <b-form-group :state="fileState" invalid-feedback="File is required">
          <b-form-file @input="fileSelected" type="file" :state="fileState" required placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
        </b-form-group>
      </b-form>
      <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
        <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
      </b-col>
    </b-modal>

    <b-modal id="modal-file-update" cancel-variant="outline-secondary" ok-title="Change File" @ok="handleUpdate" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New File">
      <b-form ref="fileRules">
        <b-form-group :state="nameState" invalid-feedback="File Name is required">
          <label for="fileName">File Name:</label>
          <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
        </b-form-group>
      </b-form>
      <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
        <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
      </b-col>
    </b-modal>
  </b-overlay>
</template>

<script>
import { VBTooltip, BImg, BMediaAside, BContainer, BBadge, BOverlay, BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BFormCheckbox } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import router from '@/router';
import Logo from '@core/layouts/components/QuoteLogo.vue';
import store from '@/store';
import leaseStoreModule from '../leaseStoreModule';
import { ref, onUnmounted, reactive } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import moment from 'moment';
import axiosIns from '@/libs/axios';

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },

  components: {
    VBTooltip,
    BImg,
    BMediaAside,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormCheckbox,
    BOverlay,
    BBadge,
    AppTimeline,
    AppTimelineItem,
    Logo,
  },

  setup() {
    const PURCHASE_APP_STORE_MODULE_NAME = 'lease';
    // Register module
    if (!store.hasModule(PURCHASE_APP_STORE_MODULE_NAME)) store.registerModule(PURCHASE_APP_STORE_MODULE_NAME, leaseStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PURCHASE_APP_STORE_MODULE_NAME)) store.unregisterModule(PURCHASE_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      where: router.currentRoute.name,
      files: [],
      modalId: null,
      modalFileName: null,
      modalFile: null,
      userRole: JSON.parse(localStorage.getItem('userData')).role,
      formShow: true,
      purchaseQuoteData: undefined,
      notApprove: '',
      download: false,
      baseURL: store.state.app.baseURL,
      fileURL: store.state.app.fileURL,
      percentCompleted: 0,
    };
  },

  created() {
    if (this.where == 'lease-preview') {
      this.getByIdQuote('getInceptionById');
    } else {
      this.getByIdQuote('getArchivePreviewQuoteById');
    }
  },

  computed: {
    creditIssued() {
      if (this.purchaseQuoteData.priceContractType == 2) {
        return (this.purchaseQuoteData.creditIssued = this.purchaseQuoteData.appraisedVehiclePrice);
      } else {
        return (this.purchaseQuoteData.creditIssued = 0);
      }
    },

    nameState() {
      return this.modalFileName != null && this.modalFileName != '' ? true : false;
    },

    fileState() {
      return this.modalFile != null && this.modalFile != '' ? true : false;
    },

    priceDifference() {
      //purchaseQuoteData.get_inventory.appraisedVehiclePrice

      if (this.purchaseQuoteData.priceContractType == 2) {
        return (this.purchaseQuoteData.priceDifference = this.purchaseQuoteData.appraisedVehiclePrice - this.creditIssued);
      } else {
        return (this.purchaseQuoteData.creditIssued = this.purchaseQuoteData.appraisedVehiclePrice - this.creditIssued);
      }
    },

    gstAmountOnVehicle() {
      if (this.priceDifference == 0) {
        return 0;
      } else if (this.purchaseQuoteData.gstRateVehicle == null || this.purchaseQuoteData.gstRateVehicle == '') {
        return 0;
      } else {
        return Number((this.priceDifference / 100) * this.purchaseQuoteData.gstRateVehicle);
      }
    },

    optionAmountTotal() {
      var total = 0;
      this.purchaseQuoteData.priceCalculationValues.forEach((element) => {
        total = Number(total) + Number(element.pcSubTotal);
      });

      return total;
    },

    contractAmount() {
      return Number(this.optionAmountTotal) + Number(this.priceDifference) + Number(this.gstAmountOnVehicle);
    },
  },

  methods: {
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    goToVehicle(val) {
      router.push({ name: 'inventory-view', params: { id: val } });
    },

    fileEdit(data) {
      this.modalId = data.id;
      // v-if="userRole != 'supermanager'" v-show="purchaseQuoteData.status == 'A' || purchaseQuoteData.status == 'B'"
      if (this.userRole == 'supermanager') {
        this.modalFileName = data.fileName;

        this.$bvModal.show('modal-file-update');
      } else if (this.purchaseQuoteData.status == 'A' || this.purchaseQuoteData.status == 'B') {
        this.modalFileName = data.fileName;
        this.$bvModal.show('modal-file-update');
      }
    },

    deleteFile(data) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          axiosIns
            .delete(`deletePurchaseFile/${data.id}`)
            .then((res) => {
              var tempList = [];
              this.files.forEach((element) => {
                if (element.id != data.id) {
                  tempList.push(element);
                }
              });

              this.files = tempList;
              this.formShow = false;
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },

    addNewFile() {
      this.$bvModal.show('modal-file');
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      if (this.percentCompleted == 100) {
        var data = {
          quoteId: this.purchaseQuoteData.id,
          fileName: this.modalFileName,
          fileLink: this.modalFile,
        };
        // Trigger submit handler
        this.modalOk(data);
      }
    },

    handleUpdate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.modalUpdate();
    },

    resetModal() {
      this.modalFileName = null;
      this.modalFile = null;
    },

    modalOk(data) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-file');
      });

      this.formShow = true;

      axiosIns
        .post('savePurchaseFile', data)
        .then((response) => {
          this.files.push({
            id: response.data.id,
            fileName: response.data.fileName,
            fileLink: response.data.fileLink,
          });

          this.modalFileName = null;
          this.modalFile = null;

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    modalUpdate(val) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-file-update');
      });

      this.formShow = true;

      var data = {
        id: this.modalId,
        fileName: this.modalFileName,
        fileLink: this.modalFile,
      };

      axiosIns
        .put('updatePurchaseFile', data)
        .then((response) => {
          this.modalFileName = null;
          this.modalFile = null;

          this.files.forEach((file) => {
            if (file.id == this.modalId) {
              file.fileName = response.data.fileName;
            }
          });

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    fileSelected(event) {
      //  this.formShow = true;
      let formData = new FormData();
      formData.append('file', event);

      const config = {
        onUploadProgress: function(progressEvent) {
          this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }.bind(this),
      };

      axiosIns
        .post('uploadPurchaseFile', formData, config)
        .then((response) => {
          this.modalFile = response.data;

          // this.tempImage = null;
          // this.selectedImage = null;
          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    checkFormValidity() {
      const nameValid = this.$refs.fileRules.checkValidity();
      const fileValid = this.$refs.fileRules.checkValidity();

      return fileValid && nameValid;
    },

    isEmptyOrSpaces(str) {
      return str === null || str == '' || str == ' ';
    },

    varyant(val) {
      if (val == 'A') {
        return 'success';
      } else if (val == 'B') {
        return 'info';
      } else if (val == 'C') {
        return 'success';
      } else if (val == 'D') {
        return '';
      } else if (val == 'E') {
        return 'warning';
      } else if (val == 'I') {
        return 'success';
      } else if (val == 'J') {
        return 'danger';
      } else if (val == 'F') {
        return 'info';
      } else if (val == 'P') {
        return 'danger';
      } else if (val == 'G') {
        return 'success';
      } else if (val == 'H') {
        return 'danger';
      } else {
        return 'success';
      }
    },

    varicon(val) {
      if (val == 'A') {
        return 'AwardIcon';
      } else if (val == 'B') {
        return 'InfoIcon';
      } else if (val == 'C') {
        return 'ClockIcon';
      } else if (val == 'D') {
        return 'InfoIcon';
      } else if (val == 'E' || val == 'I' || val == 'J') {
        return 'UserIcon';
      } else if (val == 'F') {
        return 'GridIcon';
      } else if (val == 'P') {
        return 'GridIcon';
      } else if (val == 'G') {
        return 'DollarSignIcon';
      } else if (val == 'H') {
        return 'EyeOffIcon';
      } else {
        return 'success';
      }
    },

    dateFormat(val) {
      return moment(String(val)).format('DD-MM-YYYY HH:mm');
    },

    statusChange(statusVal) {
      if (statusVal == 'D') {
        this.$swal({
          title: 'Are you sure you want to continue?',
          inputPlaceholder: 'Reason for rejecting the quote?',
          icon: 'warning',
          input: 'textarea',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Yes, reject the quote!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            var value = {
              id: router.currentRoute.params.id,
              value: statusVal,
              notes: result.value,
            };
            this.updateStatus(value);
          }
        });
      } else if (statusVal == 'MarkAsLost') {
        this.$swal({
          title: 'Are you sure you want to continue?',
          text: textValue,
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Yes, mark as lost!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            var value = {
              id: router.currentRoute.params.id,
              value: statusVal,
            };
            this.updateStatus(value);
          }
        });
      } else if (statusVal == 'R') {
        this.$swal({
          title: 'Are you sure you want to continue?',
          text: 'The status of the quote will be changed to draft.',
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Yes, revise the quote!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            var value = {
              id: router.currentRoute.params.id,
              value: statusVal,
            };
            this.updateStatus(value);
          }
        });
      } else {
        var notemptyInventory =
          this.isEmptyOrSpaces(this.purchaseQuoteData.condition) || this.isEmptyOrSpaces(this.purchaseQuoteData.mileageUnit) || this.isEmptyOrSpaces(this.purchaseQuoteData.mileageValue) || this.isEmptyOrSpaces(this.purchaseQuoteData.extColour) || this.isEmptyOrSpaces(this.purchaseQuoteData.vinNo);

        var notemptyCustomer =
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_seller_customer.sellerType) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_seller_customer.phoneNumber) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_seller_customer.streetAddress) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_seller_customer.email) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_seller_customer.provence) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_seller_customer.postalCode) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_seller_customer.country);

        if (this.purchaseQuoteData.get_lien_customer) {
          var notemptyLienHolder =
            this.isEmptyOrSpaces(this.purchaseQuoteData.get_lien_customer.sellerType) ||
            this.isEmptyOrSpaces(this.purchaseQuoteData.get_lien_customer.phoneNumber) ||
            this.isEmptyOrSpaces(this.purchaseQuoteData.get_lien_customer.streetAddress) ||
            this.isEmptyOrSpaces(this.purchaseQuoteData.get_lien_customer.email) ||
            this.isEmptyOrSpaces(this.purchaseQuoteData.get_lien_customer.provence) ||
            this.isEmptyOrSpaces(this.purchaseQuoteData.get_lien_customer.postalCode) ||
            this.isEmptyOrSpaces(this.purchaseQuoteData.get_lien_customer.country);
        }

        var notemptyQuote = this.isEmptyOrSpaces(this.purchaseQuoteData.vehicleTitleType) || this.isEmptyOrSpaces(this.purchaseQuoteData.completionDate) || this.isEmptyOrSpaces(this.purchaseQuoteData.quoteDate) || this.isEmptyOrSpaces(this.purchaseQuoteData.appraisedVehiclePrice);

        if (this.purchaseQuoteData.vehicleTitleType == 1 || this.purchaseQuoteData.vehicleTitleType == '1') {
          var condition = notemptyInventory || notemptyCustomer || notemptyQuote || notemptyDeclearation;
        } else {
          var condition = notemptyInventory || notemptyCustomer || notemptyQuote || notemptyDeclearation || notemptyLienHolder;
        }

        if (condition) {
          this.$swal({
            title: 'Fill in the missing fields!',
            text: 'You must fill in the mandatory fields before submitting it for manager approval.',
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Edit',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              if (notemptyInventory) {
                router.push({ name: 'inventory-edit-purchase', params: { id: this.purchaseQuoteData.inventoryId, purchaseid: this.purchaseQuoteData.id } });
              } else if (notemptyDeclearation) {
                router.push({ name: 'vehicle-purchase-decleration', params: { id: this.purchaseQuoteData.inventoryId, quoteid: this.purchaseQuoteData.id } });
              } else if (notemptyCustomer) {
                router.push({ name: 'customer-edit-purchase', params: { id: this.purchaseQuoteData.sellerCustomerId, quoteid: this.purchaseQuoteData.id } });
              } else if (notemptyQuote) {
                router.push({ name: 'quote-validation-edit', params: this.purchaseQuoteData.id });
              } else if (notemptyLienHolder) {
                router.push({ name: 'customer-edit-purchase', params: { id: this.purchaseQuoteData.get_lien_customer.id, quoteid: this.purchaseQuoteData.id } });
              } else {
              }

              //    :to="{ name: 'quote-edit', params: { id: $route.params.id } }
            }
          });
        } else {
          var buttonText = 'Yes, send to manager!';
          var textValue = "You won't be able to revert this!";
          if (statusVal == 'B') {
            buttonText = 'Yes, send to manager!';
          } else if (statusVal == 'C') {
            buttonText = 'Yes, approve the quote!';
          } else if (statusVal == 'D') {
            buttonText = 'Yes, reject the quote!';
          } else if (statusVal == 'E') {
            buttonText = 'Yes, send to client!';
          } else if (statusVal == 'F') {
            buttonText = 'Yes, add the vehicle to inventory!';
          } else if (statusVal == 'P') {
            buttonText = 'Yes, mark as lost!';
          } else if (statusVal == 'R') {
            buttonText = 'Yes, revise the quote!';
            textValue = 'The status of the quote will be changed to draft.';
          } else if (statusVal == 'PM') {
            buttonText = 'Yes, payment made!';
            textValue = 'The status of the quote will be changed to paid';
          } else if (statusVal == 'MarkAsLost') {
            buttonText = 'Yes, mark as lost!';
          } else if (statusVal == 'MarkCancel') {
            buttonText = 'Yes, mark as Cancel!';
          } else if (statusVal == 'MarkComplete') {
            buttonText = 'Yes, mark as Complete!';
          }

          this.$swal({
            title: 'Are you sure you want to continue?',
            text: textValue,
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: buttonText,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
            .then((result) => {
              if (result.isConfirmed) {
                var value = {
                  id: router.currentRoute.params.id,
                  value: statusVal,
                };
                this.updateStatus(value);
              }
            })
            .catch((error) => {
              console.log(error);
              this.formShow = false;

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong',
                  text: 'Please try again or report an issue to support',
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              });
            });
        }
      }
    },

    updateStatus(val) {
      this.formShow = true;
      val.email = this.purchaseQuoteData.sellerIdentification.email;
      val.updated_at = this.purchaseQuoteData.updated_at;

      var resultText = 'Your quote has been sent to manager';
      if (val.value == 'B') {
        resultText = 'Your quote has been sent to manager';
      } else if (val.value == 'C') {
        resultText = 'The quote has been approved';
      } else if (val.value == 'D') {
        resultText = 'The quote has been rejected';
      } else if (val.value == 'E') {
        resultText = 'The quote has been sent to client';
      } else if (val.value == 'F') {
        resultText = 'Vehicle has been added to inventory';
      } else if (val.value == 'P') {
        resultText = 'The Quote has been marked as lost';
      } else if (val.value == 'R') {
        resultText = 'The Quote has been changed as draft';
      } else if (val.value == 'PM') {
        resultText = 'The Quote has been changed as payment made';
      } else if (val.value == 'MarkAsLost') {
        resultText = 'The Quote has been marked as lost';
      } else if (val.value == 'MarkCancel') {
        resultText = 'The Quote has been marked as cancel';
      } else {
        resultText = '--';
      }

      store
        .dispatch('lease/updateQuoteStatus', val)
        .then((response) => {
          if (response.data != 'alreadyUpdated') {
            this.formShow = false;
            this.$swal({
              icon: 'success',
              title: 'Successful!',
              text: resultText,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });

            if (this.where == 'lease-preview') {
              this.getByIdQuote('getInceptionById');
            } else {
              this.getByIdQuote('getArchivePreviewQuoteById');
            }
          } else {
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Please refresh the page.',
                text: '⚠️ Data has been changed during operations. ',
                icon: 'RefreshCwIcon',
                variant: 'warning',
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
          this.$swal({
            icon: 'error',
            title: 'Something went wrong!',
            text: 'Please try again or report an issue to support',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
        });
    },

    imageClick(url) {
      this.$swal({
        imageUrl: url,
        imageWidth: 1000,
        imageHeight: 600,
        showConfirmButton: false,
        width: '1000px',
      });
    },

    pdfDownload(val) {
      this.download = true;
      var link = 'pdfDownload';
      if (this.where == 'lease-preview') {
        link = 'pdfDownload';
      } else {
        link = 'pdfArchiveDownload';
      }
      store.dispatch('lease/' + link, val).then((response) => {
        if (response.status == 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          if (this.where == 'lease-preview') {
            link.setAttribute('download', 'Quote_' + Number(val) + '.pdf');
          } else {
            link.setAttribute('download', 'Archive_Quote_' + Number(val) + '.pdf');
          }

          document.body.appendChild(link);
          link.click();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Quote pdf is created!',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          this.download = false;
        }
      });
    },

    getByIdQuote(val) {
      this.formShow = true;
      if (router.currentRoute.params.id) {
        store
          .dispatch('lease/' + val, router.currentRoute.params.id)
          .then((response) => {
            if (this.where == 'archive-lease-preview') {
              response.data.get_inventory = response.data.get_archive_inventory;
            }

            response.data.lienHolder = {
              sellerType: null,
              phoneNumber: null,
              firstName: null,
              lastName: null,
              email: null,

              unitNumber: null,
              provence: null,
              streetAddress: null,
              city: null,
              postalCode: null,
              country: null,
              gst: null,
              pst: null,
              dealer: null,
              driverLicenseNumber: null,
              otherIdNumber: null,
              dateOfBirth: null,
              contactPerson: null,
              contactInfo: null,
            };
            response.data.sellerIdentification = {
              sellerType: null,
              phoneNumber: null,
              firstName: null,
              lastName: null,
              email: null,

              unitNumber: null,
              provence: null,
              streetAddress: null,
              city: null,
              postalCode: null,
              country: null,
              gst: null,
              pst: null,
              dealer: null,
              driverLicenseNumber: null,
              otherIdNumber: null,
              dateOfBirth: null,
              contactPerson: null,
              contactInfo: null,
            };
            this.purchaseQuoteData = response.data;

            this.purchaseQuoteData.sellerIdentification.sellerType = response.data.lesseeType;
            this.purchaseQuoteData.sellerIdentification.phoneNumber = response.data.lesseePhone;
            this.purchaseQuoteData.sellerIdentification.firstName = response.data.lesseeName;
            this.purchaseQuoteData.sellerIdentification.lastName = response.data.lesseeLastName;
            this.purchaseQuoteData.sellerIdentification.email = response.data.lesseeEmail;

            this.purchaseQuoteData.sellerIdentification.unitNumber = response.data.lesseeUnit;
            this.purchaseQuoteData.sellerIdentification.provence = response.data.lesseeProvince;
            this.purchaseQuoteData.sellerIdentification.streetAddress = response.data.lesseeStreet;
            this.purchaseQuoteData.sellerIdentification.city = response.data.lesseeCity;
            this.purchaseQuoteData.sellerIdentification.postalCode = response.data.lesseePostal;
            this.purchaseQuoteData.sellerIdentification.country = response.data.lesseeCountry;
            this.purchaseQuoteData.sellerIdentification.gst = response.data.lesseeGst;
            this.purchaseQuoteData.sellerIdentification.pst = response.data.lesseePst;
            this.purchaseQuoteData.sellerIdentification.dealer = response.data.lesseeDealer;
            this.purchaseQuoteData.sellerIdentification.customerId = response.data.lesseeCustomerId;

            // this.purchaseQuoteData.sellerIdentification.contactPerson = response.data.contactPerson;
            // this.purchaseQuoteData.sellerIdentification.contactInfo = response.data.contactInfo;

            this.purchaseQuoteData.sellerIdentification.driverLicenseNumber = response.data.lesseeLicense;
            this.purchaseQuoteData.sellerIdentification.otherIdNumber = response.data.lesseeOtherId;
            this.purchaseQuoteData.sellerIdentification.dateOfBirth = response.data.lesseeDateOfBirth;

            this.purchaseQuoteData = response.data;
            this.notApprove = this.purchaseQuoteData.notes;

            this.formShow = false;
          })
          .catch((error) => {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            router.push({ name: 'quote-list' });
          });
      } else {
        this.formShow = false;
      }
    },
  },
};
</script>

<style>
.textlongvalue {
  line-height: 1.84;
}

.title {
  font-size: 12px;
}

.textproduct {
  font-size: 12px;
}

.noborderright {
  border-right: hidden !important;
}

.valueinput {
  font-size: 16px;
}
</style>
